import request from '../request'

export function fetchList(params) {
    return request({
        url: '/common-service/partyWork/list',
        params,
    })
}

export function add(data) {
    return request({
        url: '/common-service/partyWork/add',
        data,
        method: "post",
    })
}

export function update(data) {
    return request({
        url: '/common-service/partyWork/update',
        data,
        method: "post",
    })
}

export function fetchDetail(params) {
    return request({
        url: '/common-service/partyWork/query/' + params.id,
    })
}

export function remove(data) {
    return request({
        url: '/common-service/partyWork/del',
        data,
        method: "post",
    })
}